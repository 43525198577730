import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Input,
  Checkbox,
} from "@material-ui/core";
import LinkPreview from "@ashwamegh/react-link-preview";
import { Delete } from "@material-ui/icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";

import { setLoader } from "../../../redux/ducks/load.duck";
import * as contentsDUCK from "../../../redux/ducks/contents.duck";
import * as tagsDUCK from "../../../redux/ducks/tags.duck";
import { DropFiles, DropMultipleFiles } from "../../utils/DropFiles";
import {
  deleteContent,
  getContents,
  saveContent,
  updateContent,
} from "../../crud/api/content.crud";
import { Dropper } from "../../utils/DropNCrop";
import { setAlert } from "../../../redux/ducks/alert.duck";
import { previewLinkCard } from "../../utils/PreviewLinkCard";
import CustomEditor from "../../utils/CustomEditor";
import { getFocusAreas } from "../../crud/api/focus_areas.crud";
import { getTags } from "../../crud/api/tags.crud";
import * as focusAreasDUCK from "../../../redux/ducks/focus_areas.duck.js";
import { getParentFactor } from "../../crud/api/subfactors.crud";

const ContentsDialog = ({
  mode,
  setDialog,
  content,
  contentActions,
  settingLoader,
  setAlert,
  page,
  focusAreasActions,
  school,
  focus_areas = [],
  tags = [],
  onCreate,
  tagsActions,
}) => {
  const open = mode === "editing" || mode === "create";
  const title = mode === "editing" ? "Edit Content" : "New Content";
  const [loading, setLoading] = useState(false);
  const [contributing_factors, setContributingFactors] = useState([]);
  const categories = [
    { key: 1, name: "Mind" },
    { key: 2, name: "Body" },
    { key: 3, name: "Personal Life" },
  ];
  // const contributing_factors = [
  //   { key: 1, name: "Academics" },
  //   { key: 2, name: "Physical" },
  //   { key: 3, name: "Personal" },
  //   { key: 3, name: "Others" },
  // ];
  const moods = [
    { key: 1, name: "Great" },
    { key: 2, name: "Alright" },
    { key: 3, name: "Not Great" },
  ];

  const [filteredTags, setFilteredTags] = useState(tags);
  const [filteredFocusAreas, setFilteredFocusAreas] = useState(focus_areas);

  useEffect(() => {
    getFocusAreas(focusAreasActions.setFocusAreas, school?.slug, 1);
    getTags(tagsActions.setTags, school?.slug, 1, 200);
    if (contributing_factors.length === 0) {
      getParentFactor(setContributingFactors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusAreasActions.setFocusAreas, focusAreasActions.setTags?.slug]);

  const create = async (values) => {
    try {
      await saveContent(contentActions.updateContents, values);
      setLoading(false);
      setDialog("close");
      onCreate();
    } catch (error) {
      setLoading(false);
    }
  };
  const is_disabled = (values) => {
    if (values.content_type === "tip")
      if (
        values.content === undefined ||
        !values.name ||
        !values.content_type ||
        !values.description_content ||
        values.moods_id.length === 0 ||
        values.contributing_factors_id.length === 0
      )
        return true;
      else return false;
    else if (
      (values.content === undefined
        ? true
        : !content.id
        ? false
        : values.resource_url_slides.length === 0) ||
      !values.name ||
      !values.content_type ||
      !values.duration ||
      !values.author_name ||
      !values.content_partner ||
      !values.description ||
      values.categories_id.length === 0
    )
      return true;
    else return false;
  };
  const update = async (values) => {
    try {
      const payload = {
        author_name: values.author_name,
        categories_id: values.categories_id,
        content: values.content,
        content_partner: values.content_partner || "",
        content_type: values.content_type,
        contributing_factors_id: values.contributing_factors_id,
        description: values.description,
        description_content: values.description_content,
        is_pro_athlete: values.is_pro_athlete,
        duration: values.duration,
        focus_areas_ids: values.focus_areas_ids,
        tags_ids: values.tags_ids,
        image: values.image || null,
        logo: values.logo || null,
        moods_id: values.moods_id,
        name: values.name,
        is_tip: values.is_tip,
      };
      await updateContent(
        contentActions.updateContents,
        { ...payload, content_id: content.id },
        content.id
      );
      setLoading(false);
      setDialog("close");
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteCont = (id) => {
    setAlert({
      title: "Delete Content",
      message: "Are you sure to permanently delete this Content?",
      btn_msg: "Delete Content",
      action: () =>
        deleteContent(id).then(() => {
          setDialog("close");
          getContents(contentActions.setContents, page);
        }),
    });
  };

  const permitedFiles = (type) => {
    if (type === "pdf") return "application/pdf";
    if (type === "audio") return "audio/*";
    if (type === "video") return "video/*";
    if (type === "image") return "image/*";
    if (type === "tip") return "image/*, .gif, .json";
    if (type === "document")
      return "application/pdf,application/msword, .doc, .docx, .ppt, .pptx, .gdoc, .gdocs, .xls, .xlsx, .csv, .txt";
    else return "*";
  };

  const filterProTags = (allTags) => {
    const proTags = allTags.data.filter((t) => t.is_pro_athlete === true);
    setFilteredTags({ ...tags, data: proTags });
  };

  const filterProFocusAreas = (allAreas) => {
    const proFocusAreas = allAreas.filter((t) => t.is_pro_athlete === true);
    setFilteredFocusAreas(proFocusAreas);
  };

  useEffect(() => {
    if (school.is_pro_athlete) {
      filterProTags(tags);
      filterProFocusAreas(focus_areas);
    }
  }, [tags, focus_areas]);

  return (
    <Dialog
      open={open}
      onClose={() => setDialog("close")}
      maxWidth="md"
      fullWidth
      id="content_dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...content,
          notify_students: false,
          focus_areas_ids: content.focus_areas_ids
            ? content.focus_areas_ids
            : [],
          tags_ids: content.tags_ids ? content.tags_ids : [],
          categories_id: content.categories_id ? content.categories_id : [],
          contributing_factors_id: content.contributing_factors_id
            ? content.contributing_factors_id
            : [],
          moods_id: content.moods_id ? content.moods_id : [],
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) errors.name = "Required field";
          if (!values.content_type) errors.content_type = "Required field";
          // if (!values.duration)errors.duration = "Required field";
          // if (!values.author_name)errors.author_name = "Required field";

          // if (!values.content_partner)errors.content_partner = "Required field";
          // if (!values.description)errors.description = "Required field";
          // if (!values.categories_id)errors.categories_id = "Required field";

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          settingLoader(true);
          setLoading(true);

          values.content =
            values.content !== "" && values.content != null
              ? values.content
              : values.resource_url_slides;
          content.id ? update(values) : create(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={1}>
                {values.content_type !== "tip" ? (
                  <Grid container item spacing={2} xs={4}>
                    <Grid
                      item
                      xs={12}
                      style={{ position: "absolute", marginTop: -25 }}
                    >
                      <h6>Display image</h6>
                    </Grid>
                    <Grid item xs={12}>
                      <Dropper
                        setterFunction={setFieldValue}
                        file={values.image}
                        field={"image"}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid
                  container
                  item
                  spacing={2}
                  xs={values.content_type === "tip" ? 12 : 8}
                >
                  {/* <Grid container item xs={12} className="ml-0 mt-2">
                  <FormControlLabel
                 
                    className="ml-0 pl-0 text-left "
                    label="Is a tip?"
                    labelPlacement="start"
                    control={
                      <Switch
                        id="content_tip_switch_dialog"
                        name="is_tip"
                        checked={values.is_tip}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                  />
                </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      id="content_name_input_dialog"
                      name="name"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      required
                      fullWidth
                      variant="outlined"
                      id="content_select_type_input_dialog"
                      label="Type of content"
                      name="content_type"
                      value={values.content_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.content_type && errors.content_type}
                      error={Boolean(
                        touched.content_type && errors.content_type
                      )}
                    >
                      <MenuItem value="audio" id="content_type_audio">
                        Audio
                      </MenuItem>
                      <MenuItem value="video" id="content_type_video">
                        Video
                      </MenuItem>
                      <MenuItem value="document" id="content_type_document">
                        Document
                      </MenuItem>
                      <MenuItem value="html" id="content_type_html">
                        Text
                      </MenuItem>
                      <MenuItem value="image" id="content_type_link">
                        Image
                      </MenuItem>
                      <MenuItem value="tip" id="content_type_tip">
                        Tip
                      </MenuItem>
                    </TextField>
                  </Grid>

                  {values.content_type && (
                    <Grid item xs={12}>
                      {values.content_type === "html" ? (
                        <CustomEditor
                          value={values.content}
                          setFieldValue={setFieldValue}
                        />
                      ) : values.content_type === "link" ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="content_link_input_dialog"
                          label="Link"
                          name="content"
                          type="url"
                          value={values.content}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      ) : values.content_type === "image" ? (
                        values.content_type && (
                          <>
                            <Grid item xs={12}>
                              <h6 style={{ marginBottom: "10px" }}>
                                Add images
                              </h6>
                            </Grid>
                            <Grid item xs={12}>
                              <DropMultipleFiles
                                setterFunction={setFieldValue}
                                field="content"
                                files={
                                  values.resource_url_slides != null
                                    ? values.resource_url_slides
                                    : values.content
                                }
                                value={values.content}
                                type={permitedFiles(values.content_type)}
                              />
                            </Grid>
                          </>
                        )
                      ) : (
                        values.content_type && (
                          <>
                            <Grid item xs={12}>
                              <h6>Add content</h6>
                            </Grid>
                            <Grid item xs={12}>
                              <DropFiles
                                setterFunction={setFieldValue}
                                field="content"
                                value={values.content}
                                type={permitedFiles(values.content_type)}
                              />
                            </Grid>
                          </>
                        )
                      )}
                    </Grid>
                  )}
                  {values.content_type &&
                    values.content_type === "link" &&
                    values.content &&
                    (values.content.includes("http", 0) ||
                      values.content.includes("https", 0)) && (
                      <Grid item xs={12}>
                        <LinkPreview
                          url={values.content}
                          render={(data) =>
                            previewLinkCard(data, values.content)
                          }
                        />
                      </Grid>
                    )}
                  {values.content_type === "tip" ? (
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="content_description_input_dialog"
                        name="description_content"
                        label="Tip Text"
                        value={values.description_content}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.description_content &&
                          errors.description_content
                        }
                        error={Boolean(
                          touched.description_content &&
                            errors.description_content
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="content_duration_input_dialog"
                        name="duration"
                        label="Duration"
                        value={values.duration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.duration && errors.duration}
                        error={Boolean(touched.duration && errors.duration)}
                      />
                    </Grid>
                  )}

                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      id="content_duration_min_input_dialog"
                      name="duration_minutes"
                      label="Duration(minutes)"
                      value={values.duration_minutes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.duration_minutes && errors.duration_minutes
                      }
                      error={Boolean(
                        touched.duration_minutes && errors.duration_minutes
                      )}
                    />
                  </Grid> */}
                </Grid>
                {values.content_type !== "tip" && (
                  <>
                    <Divider style={{ width: "100%" }} />
                    <Grid container item xs={12}>
                      <h6 style={{ marginTop: 10, marginBottom: 30 }}>
                        About The Author
                      </h6>
                    </Grid>
                    <Grid
                      container
                      item
                      spacing={2}
                      xs={4}
                      style={{ position: "relative" }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ position: "absolute", marginTop: -25 }}
                      >
                        <h6>Logo</h6>
                      </Grid>
                      <Grid item xs={12}>
                        <Dropper
                          setterFunction={setFieldValue}
                          file={values.logo}
                          field={"logo"}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item spacing={2} xs={8}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          id="content_author_name_input_dialog"
                          name="author_name"
                          label="Author name"
                          value={values.author_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.author_name && errors.author_name}
                          error={Boolean(
                            touched.author_name && errors.author_name
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          id="content_partner_input_dialog"
                          name="content_partner"
                          label="Content Partner"
                          value={values.content_partner}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            touched.content_partner && errors.content_partner
                          }
                          error={Boolean(
                            touched.content_partner && errors.content_partner
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          id="content_description_input_dialog"
                          name="description"
                          label="Description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.description && errors.description}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                        />
                      </Grid>
                    </Grid>
                    {values.content_type !== "tip" && (
                      <>
                        <Grid container item xs={12}>
                          <Divider />
                          <h6 style={{ marginTop: 10 }}>Category *</h6>
                        </Grid>
                        <Grid container item xs={12}>
                          <Select
                            multiple
                            required
                            fullWidth
                            id="content_select_category_input_dialog"
                            label="Category"
                            name="categories_id"
                            value={values.categories_id}
                            input={<Input variant="outlined" />}
                            renderValue={(selected) =>
                              categories
                                .filter((x) => selected.includes(x.key))
                                .map((n) => {
                                  return n.name;
                                })
                                .join(",")
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              touched.categories_id && errors.categories_id
                            }
                            error={Boolean(
                              touched.categories_id && errors.categories_id
                            )}
                          >
                            {categories.map((fa) => (
                              <MenuItem key={fa.key} value={fa.key}>
                                {fa.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </>
                    )}
                    <Grid container item xs={12}>
                      <Divider />
                      <h6 style={{ marginTop: 10 }}>Tags</h6>
                    </Grid>
                    <Grid container item xs={12}>
                      <Select
                        multiple
                        required
                        fullWidth
                        id="content_select_tag_input_dialog"
                        label="Tag"
                        name="tags_ids"
                        value={values.tags_ids}
                        input={<Input variant="" />}
                        renderValue={(selected) =>
                          filteredTags.data
                            .filter((x) => selected.includes(x.id))
                            .map((n) => {
                              return n.name;
                            })
                            .join(",")
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.tags_ids && errors.tags_ids}
                        error={Boolean(touched.tags_ids && errors.tags_ids)}
                      >
                        {filteredTags.data.map((fa) => (
                          <MenuItem key={fa.id} value={fa.id}>
                            {fa.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid container item xs={12}>
                      <Divider />
                      <h6 style={{ marginTop: 10 }}>Focus Areas</h6>
                    </Grid>

                    <Grid container item xs={12}>
                      <Select
                        multiple
                        required
                        fullWidth
                        id="content_select_type_input_dialog"
                        label="Focus Areas"
                        name="focus_areas_ids"
                        value={values.focus_areas_ids}
                        input={<Input variant="outlined" />}
                        renderValue={(selected) =>
                          filteredFocusAreas
                            .filter((x) => selected.includes(x.id))
                            .map((n) => {
                              return n.name;
                            })
                            .join(",")
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.focus_areas_ids && errors.focus_areas_ids
                        }
                        error={Boolean(
                          touched.focus_areas_ids && errors.focus_areas_ids
                        )}
                      >
                        {filteredFocusAreas.map((fa) => (
                          <MenuItem key={fa.id} value={fa.id}>
                            {fa.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
                <Grid container item xs={12}>
                  <Divider />
                  <h6 style={{ marginTop: 10 }}>Mood</h6>
                </Grid>
                <Grid container item xs={12}>
                  <Select
                    multiple
                    required
                    fullWidth
                    id="content_select_mood_input_dialog"
                    label="Mood"
                    name="moods_id"
                    value={values.moods_id}
                    input={<Input variant="outlined" />}
                    renderValue={(selected) =>
                      moods
                        .filter((x) => selected.includes(x.key))
                        .map((n) => {
                          return n.name;
                        })
                        .join(",")
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.moods_id && errors.moods_id}
                    error={Boolean(touched.moods_id && errors.moods_id)}
                  >
                    {moods.map((fa) => (
                      <MenuItem key={fa.key} value={fa.key}>
                        {fa.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid container item xs={12}>
                  <Divider />
                  <h6 style={{ marginTop: 10 }}>Contributing Factors</h6>
                </Grid>
                <Grid container item xs={12}>
                  <Select
                    multiple
                    required
                    fullWidth
                    id="content_select_contributing_factors_id_input_dialog"
                    label="Contributing Factors"
                    name="contributing_factors_id"
                    value={values.contributing_factors_id}
                    input={<Input />}
                    renderValue={(selected) =>
                      contributing_factors
                        .filter((x) => selected.includes(x.value))
                        .map((n) => {
                          return n.text;
                        })
                        .join(",")
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.contributing_factors_id &&
                      errors.contributing_factors_id
                    }
                    error={Boolean(
                      touched.contributing_factors_id &&
                        errors.contributing_factors_id
                    )}
                  >
                    {contributing_factors.map((fa) => (
                      <MenuItem key={fa.value} value={fa.value}>
                        {fa.text}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3} className="ml-0 mt-2">
                  <Checkbox
                    required
                    id="is_pro_athlete"
                    name="is_pro_athlete"
                    checked={values.is_pro_athlete}
                    onChange={handleChange}
                  />
                  <span>Pro atlhete</span>
                </Grid>
                {values.content_type !== "tip" && mode === "create" && (
                  <Grid container item xs={3} className="ml-0 mt-2">
                    <FormControlLabel
                      style={{ minWidth: 180 }}
                      className="ml-0 pl-0 text-left justify-content-end"
                      label="Notify Athletes"
                      labelPlacement="start"
                      control={
                        <Switch
                          id="content_notify_switch_dialog"
                          name="notify_students"
                          checked={values.notify_students}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={content.id && "justify-content-between"}>
              {content.id && (
                <button
                  id="content_delete_btn_dialog"
                  type="reset"
                  className="btn btn-danger"
                  onClick={() => deleteCont(content.id)}
                >
                  <Delete /> Delete Content
                </button>
              )}
              <div className="d-flex">
                <Button
                  id="content_cancel_btn_dialog"
                  onClick={() => setDialog("close")}
                  color="secondary"
                >
                  Cancel
                </Button>
                <button
                  type="submit"
                  id="content_submit_btn_dialog"
                  disabled={is_disabled(values)}
                  className="btn btn-primary btn-elevate kt-login__btn-primary d-flex"
                >
                  {mode === "editing" ? "Submit " : "Create"}
                  {loading && (
                    <CircularProgress
                      color="inherit"
                      size={18}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </button>
              </div>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = (store) => {
  return {
    content: store.contents_.content,
    school: store.school.selected_school,
    focus_areas: store.focus_areas.focus_areas,
    tags: store.tags.tags,
  };
};

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  contentActions: bindActionCreators(contentsDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
  focusAreasActions: bindActionCreators(focusAreasDUCK.actions, dispatch),
  tagsActions: bindActionCreators(tagsDUCK.actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentsDialog);
